<template>
  <div>
    <v-data-table
      :fixed-header="false"
      :height="undefined"
      v-bind="dataTableAttrs"
      :headers="headersShown"
      :items="list"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page.sync="itemsPerPage"
      v-model="selectedItems"
      @update:items-per-page="handleItemsPerPageUpdated"
    >
      <template v-slot:top>
        <rs-table-title-bar
          v-if="$refs.headSearch && allowAdd && can('edit-document')"
          title="Belgeler"
          icon=""
          :add-route="allowAdd && can('edit-document') ? '#' : null"
          @click:add.prevent="handleAddClick"
          @clear-filters="$refs.headSearch.reset"
          @reload="loadList"
          @click:edit="handleEditClick"
          @click:delete="handleDeleteClick"
          :edit-enabled="allowAdd && selectedItems.length === 1"
          :show-delete="allowAdd"
          :delete-enabled="selectedItems.length > 0"
          :search.sync="search.query"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.prepend="{ headers }">
        <rs-table-head-search
          :headers="headers"
          :search.sync="search"
          ref="headSearch"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.edit="{ item }" v-if="allowAdd">
        <v-btn text icon @click="handleEditClick(item)">
          <i class="menu-icon mdi mdi-pencil"></i>
        </v-btn>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.subject="{ item }">
        {{ item.subject }}
        <v-icon v-if="item.document_count > 0" title="Ekli belge mevcut">
          mdi-paperclip
        </v-icon>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.title="{ item }">
        <a
          href="#"
          @click.prevent="handleItemClick(item)"
          :title="$t('download')"
        >
          {{ item.title }}
        </a>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.created_at="{ item }">
        {{ new Date(item.created_at).toLocaleString() }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.size="{ value }">
        {{ getFileSizeHuman(value) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.document_type="{ item, value }">
        {{ value }}
        <em>({{ item.extension.toUpperCase() }})</em>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.shared_with_residents="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>
    </v-data-table>

    <DocumentForm
      :extra-params="extraParams"
      @dismissed="handleFormDismiss"
      @saved="handleFormSaved"
      ref="documentForm"
    />

    <rs-confirm
      ref="confirmDelete"
      :loading="isLoading"
      @confirmed="handleDeleteSubmitClick"
      @cancelled="$refs.confirmDelete.hide()"
    />
  </div>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasPermissions } from "@/view/mixins";
import DocumentForm from "@/view/content/documents/DocumentForm";

export default {
  components: {
    DocumentForm,
  },
  mixins: [hasDataTable, hasPermissions],
  props: {
    allowAdd: {
      default: false,
      required: false,
      type: Boolean,
    },
    extraParams: {
      default: () => {},
      required: true,
      type: Object,
    },
    itemCount: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: this.$t("labels.name"),
          value: "title",
          searchable: "text",
        },
        {
          text: this.$t("labels.file_type"),
          value: "document_type",
          searchable: "text",
        },
        {
          text: this.$t("labels.file_size"),
          value: "size",
          searchable: "number",
          align: "end",
        },
        {
          text: "Tarih",
          value: "created_at",
          searchable: "date",
        },
        {
          text: "Sakinlerle Paylaş",
          value: "shared_with_residents",
          searchable: "isActive",
          align: "center",
          hide: () =>
            this.$router.currentRoute.name !== "definitions.clusters.edit",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    }, 100);
  },
  watch: {
    extraParams: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.selectedItems = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options),
        this.extraParams
      );

      this.$api
        .query("documents", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.$emit("update:item-count", this.total);
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleAddClick() {
      this.$refs.documentForm.show();
    },
    handleEditClick(item) {
      if (!item.id) {
        item = this.selectedItems[0];
      }
      this.$refs.documentForm.show(item.id, !this.can("edit-document"));
    },
    handleFormSaved() {
      this.options.page = 1;
      this.loadList();
    },
    handleItemClick(item) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      this.$api
        .get("documents", item.id)
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href = response.data.data.url;
          anchor.target = "_blank";
          anchor.download =
            response.data.data.title + "." + response.data.data.extension;
          anchor.click();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getFileSizeHuman(sizeInBytes) {
      let size, unit;

      if (sizeInBytes <= 1024) {
        size = sizeInBytes;
        unit = "b";
      } else if (sizeInBytes <= Math.pow(1024, 2)) {
        size = Math.ceil(sizeInBytes / 1024);
        unit = "KB";
      } else if (sizeInBytes <= Math.pow(1024, 3)) {
        size = Math.ceil(sizeInBytes / Math.pow(1024, 2));
        unit = "MB";
      } else if (sizeInBytes <= Math.pow(1024, 4)) {
        size = Math.ceil(sizeInBytes / Math.pow(1024, 3));
        unit = "GB";
      } else if (sizeInBytes <= Math.pow(1024, 5)) {
        size = Math.ceil(sizeInBytes / Math.pow(1024, 4));
        unit = "TB";
      }

      return size.toLocaleString() + " " + unit;
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show("Silmek istediğinizden emin misiniz?");
    },
    handleDeleteSubmitClick() {
      if (this.isLoading) return false;

      this.isLoading = true;
      const promises = [];

      for (const index in this.selectedItems) {
        const promise = this.$api.delete(
          `documents/${this.selectedItems[index].id}`
        );
        promises.push(promise);
      }

      Promise.all(promises)
        .then(() => {
          this.$toast.success("Seçilen belgeler silindi");
        })
        .catch(() => {
          this.$toast.error("Bunu yapmaya yetkili değilsiniz.");
        })
        .finally(() => {
          this.$refs.confirmDelete.hide();
          this.isLoading = false;
          this.loadList();
        });
    },
  },
};
</script>
