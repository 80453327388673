<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Belge</template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <rs-text-field
          :label="$t('labels.title')"
          :rules="[rules.required]"
          v-model="formData.title"
          :disabled="readonly ? true : disabled"
        />

        <v-file-input
          :label="$t('labels.document')"
          :rules="[]"
          accept="*"
          @change="handleFileChange"
          :disabled="readonly ? true : disabled"
        />

        <v-switch
          :label="
            this.$router.currentRoute.name === 'definitions.residents.edit'
              ? 'Sakinle Paylaş'
              : 'Sakinlerle Paylaş'
          "
          v-model="formData.shared_with_residents"
          :disabled="readonly ? true : disabled"
          hide-details="auto"
          v-if="shareAvailable"
        />

        <rs-form-buttons
          hide-submit
          :is-loading="isLoading"
          @cancel="hide()"
          @submit="handleFormSubmit"
          :hide-save-close="readonly"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { hasForm } from "@/view/mixins";

export default {
  name: "DocumentForm",
  mixins: [hasForm],
  props: {
    extraParams: {
      default: () => {},
      required: false,
      type: Object,
    },
  },
  computed: {
    shareAvailable() {
      return (
        this.$router.currentRoute.name === "definitions.clusters.edit" ||
        this.$router.currentRoute.name === "definitions.residents.edit"
      );
    },
  },
  data() {
    return {
      formData: {
        title: null,
        shared_with_residents: false,
      },
      file: null,
      id: null,
      readonly: false,
    };
  },
  methods: {
    show(documentId, readonly) {
      this.readonly = false;
      this.formData = {
        title: null,
        shared_with_residents: false,
      };
      this.file = null;
      this.id = null;
      this.originalData = {};

      if (documentId) {
        this.id = documentId;
        this.readonly = readonly;
        this.load();
      }

      this.$refs.sidePanel.show();
    },
    load() {
      if (this.isLoading || !this.id) {
        return;
      }

      this.isLoading = true;

      this.$api
        .query(`documents/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);

          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const data = new FormData();
      if (this.file) {
        data.append("document", this.file);
      }
      data.append("title", this.formData.title);
      // data.append("cluster_id", this.$route.params.id);

      data.append("shared_with_residents", this.formData.shared_with_residents);

      for (const index in this.extraParams) {
        data.append(index, this.extraParams[index]);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let url = "documents";

      if (this.id) {
        data.append("_method", "put");
        url += `/${this.id}`;
      }
      this.$api
        .post(url, data, config)
        .then((response) => {
          this.$toast.success("Kaydedildi");
          this.$emit("saved", response.data.data);
          if (event && event.closeOnSave) {
            this.hide();
            return;
          }

          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFileChange(file) {
      if (!file) {
        this.file = null;
        return;
      }

      // const allowedMimes = [
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //   "application/vnd.oasis.opendocument.spreadsheet",
      //   "application/vnd.ms-excel.sheet.macroEnabled.12",
      // ];
      //
      // if (allowedMimes.indexOf(file.type) === -1) {
      //   this.file = null;
      //   this.$toast.error("Sadece XSLM, XLSX veya ODS yükleyebilirsiniz.");
      //   return;
      // }

      this.file = file;
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
