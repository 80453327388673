<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Müşteri</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Müşteri Ekle</span>
      </h3>
      <v-col class="text-right p-0">
        <!-- <rs-action
          @click="showActionLogs"
          v-if="id"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action> -->
        <rs-action
          :href="
            'https://uygulama.parasut.com/416690/musteriler/' +
            originalData.accounting_id
          "
          target="_blank"
          v-if="id && originalData.accounting_id"
          icon
          title="Paraşüt'te göster"
        >
          P
        </rs-action>
      </v-col>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab to="#info" replace>Genel</v-tab>
        <v-tab to="#clusters" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.cluster_count || '0'"
          >
            Toplu Yaşam Alanları
          </v-badge>
        </v-tab>
        <v-tab to="#products" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.product_count || '0'"
          >
            Ürünler
          </v-badge>
        </v-tab>
        <v-tab to="#invoices" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.invoice_count || '0'"
          >
            Faturalar
          </v-badge>
        </v-tab>
        <v-tab to="#payments" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.payment_count || '0'"
          >
            Ödemeler
          </v-badge>
        </v-tab>
        <v-tab to="#cards" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.card_count || '0'"
          >
            Kayıtlı Kartlar
          </v-badge>
        </v-tab>
        <v-tab to="#notes" replace :disabled="!id">
          <v-badge color="deep-purple lighten-3" :content="noteCount || '0'">
            {{ $t("headers.notes_and_reminders") }}
          </v-badge>
        </v-tab>
        <v-tab to="#documents" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="documentCount || '0'"
          >
            {{ $t("headers.documents") }}
          </v-badge>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="info" class="pa-4">
          <v-form
            :readonly="disabled"
            @submit.prevent.stop="handleFormSubmit"
            ref="form"
            :disabled="isLoading || disabled"
          >
            <v-row dense>
              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.name')"
                  :rules="[rules.required, rules.maxLength(formData.name, 150)]"
                  v-model="formData.name"
                  hide-details="auto"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  label="Unvan"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.title, 200),
                  ]"
                  v-model="formData.title"
                  hide-details="auto"
                />
              </v-col>

              <v-col sm="6">
                <rs-select-location
                  :label="$t('labels.city')"
                  :rules="[rules.required]"
                  v-model="formData.city_id"
                  :readonly="disabled"
                  :filled="disabled"
                  :location-type-id="2"
                  required
                />
              </v-col>

              <v-col sm="6">
                <rs-select-location
                  :label="$t('labels.district')"
                  :rules="[rules.required]"
                  v-model="formData.district_id"
                  :readonly="disabled"
                  :filled="disabled"
                  :location-type-id="3"
                  :parent-id="formData.city_id"
                  required
                  :disabled="!formData.city_id"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.address')"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.address, 1000),
                  ]"
                  v-model="formData.address"
                />
              </v-col>
              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.email')"
                  :rules="[rules.maxLength(formData.email, 100)]"
                  v-model="formData.email"
                  type="email"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.tax_number')"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.tax_number, 11),
                  ]"
                  v-model="formData.tax_number"
                  hint="Önemli: Muhasebe uygulamasıyla eşleştirme için kullanılır."
                  persistent-hint
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.tax_office')"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.tax_office, 50),
                  ]"
                  v-model="formData.tax_office"
                />
              </v-col>

              <v-col sm="6">
                <rs-autocomplete-user
                  :label="$t('labels.authorized_person')"
                  v-model="formData.authorized_person_id"
                  :disabled="disabled"
                  :extra-params="{ is_active: true }"
                />
              </v-col>

              <v-col sm="6">
                <rs-select-customer-group
                  ref="selectCustomerGroup"
                  v-model="formData.customer_group_id"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  label="Muhasebe ID"
                  v-model="formData.accounting_id"
                  readonly
                />
              </v-col>

              <v-col sm="6">
                <rs-select
                  label="Ödeme Uyarı Seviyesi"
                  v-model="formData.payment_warning_level"
                  :readonly="disabled"
                  :filled="disabled"
                  :items="paymentWarningLevels"
                />
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.is_active"
                  :label="$t('labels.is_active')"
                  hide-details="auto"
                  hint="Pasif durumdaki müşterilerin muhasebesi işletilmez."
                  persistent-hint
                ></v-switch>
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.has_automatic_billing"
                  label="Otomatik Fatura"
                  hide-details="auto"
                  hint="Aktif müşterilere otomatik fatura kesilir."
                  persistent-hint
                ></v-switch>
              </v-col>
            </v-row>

            <rs-form-buttons
              :isLoading="isLoading"
              @cancel="handleCancelClick"
              @submit="handleFormSubmit"
              v-if="!disabled"
            />
          </v-form>
        </v-tab-item>
        <v-tab-item value="clusters" class="pa-4">
          <CustomerClusterList :customer-id="id" />
        </v-tab-item>
        <v-tab-item value="products" class="pa-4">
          <CustomerProductList :customer-id="id" />
        </v-tab-item>
        <v-tab-item value="invoices" class="pa-4">
          <CustomerInvoiceList :customer-id="id" />
        </v-tab-item>
        <v-tab-item value="payments" class="pa-4">
          <CustomerPaymentList :customer-id="id" />
        </v-tab-item>
        <v-tab-item value="cards" class="pa-4">
          <UserCardList :user-id="id" />
        </v-tab-item>
        <v-tab-item value="notes" class="pa-4" :eager="id">
          <NoteList
            :allow-add="true"
            :extra-params="{ customer_id: id }"
            :item-count.sync="noteCount"
            v-if="id"
            :form-subtitle="originalData.name"
          />
        </v-tab-item>
        <v-tab-item value="documents" class="pa-4" :eager="id">
          <DocumentList
            :allow-add="true"
            :extra-params="{ customer_id: id }"
            :item-count.sync="documentCount"
            v-if="id"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { hasForm } from "@/view/mixins";
import {
  CustomerClusterList,
  CustomerInvoiceList,
  CustomerPaymentList,
  CustomerProductList,
} from "../components";
import { paymentWarningLevels } from "../../../../core/data/options";
import NoteList from "@/view/content/Notes/NoteList";
import DocumentList from "@/view/content/documents/DocumentList";

export default {
  mixins: [hasForm],
  components: {
    CustomerClusterList,
    CustomerInvoiceList,
    CustomerPaymentList,
    CustomerProductList,
    DocumentList,
    NoteList,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  data() {
    return {
      paymentWarningLevels,
      selectedTab: "info",
      formData: {
        name: null,
        title: null,
        email: null,
        address: null,
        tax_office: null,
        tax_number: null,
        authorized_person_id: null,
        accounting_id: null,
        is_active: true,
        has_automatic_billing: true,
        city_id: null,
        district_id: null,
        customer_group_id: null,
        payment_warning_level: 0,
      },
      totals: {},
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`api-super-admin/customers/${this.id}`)
        .then((response) => {
          this.loadData(response);

          this.totals = response.data.data.totals || {};
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (formData.phone) {
        formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
      }

      let promise;

      if (this.id) {
        promise = this.$api
          .put(`api-super-admin/customers/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        promise = this.$api
          .post(`api-super-admin/customers`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "admin.crm.customers.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }

      promise.then(() => {
        if (
          this.$refs.selectCustomerGroup &&
          this.$refs.selectCustomerGroup.loadList
        ) {
          this.$refs.selectCustomerGroup.loadList();
        }
      });
    },
    handleCancelClick() {
      this.$router.back();
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Resident", this.id);
    },
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => {
        this.load();
      });
    }
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}

.identity-number input {
  letter-spacing: 0.3vw;
}
</style>
