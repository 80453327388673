export const techWorkerAuthorizationOptions = [
  {
    id: 1,
    translation_key: "cluster.tech_worker_authorization.view_only",
  },
  {
    id: 2,
    translation_key:
      "cluster.tech_worker_authorization.can_approve_and_comment",
  },
  {
    id: 3,
    translation_key: "cluster.tech_worker_authorization.view_only",
  },
];

export const shareMeterRoundingTypeOptions = [
  {
    id: 1,
    translation_key: "cluster.share_meter_rounding.no_rounding",
  },
  {
    id: 2,
    translation_key: "cluster.share_meter_rounding.round",
  },
];

export const bankWorkflowCollectionTypeOptions = [
  {
    id: 1,
    translation_key: "provider.bank_workflow_type.1",
  },
  {
    id: 2,
    translation_key: "provider.bank_workflow_type.2",
  },
];

export const providerBankWorkflowCollectionTypeOptions = [
  {
    id: 0,
    translation_key: "provider.provider_bank_workflow_type.0",
  },
  {
    id: 1,
    translation_key: "provider.provider_bank_workflow_type.1",
  },
  {
    id: 2,
    translation_key: "provider.provider_bank_workflow_type.2",
  },
  {
    id: 3,
    translation_key: "provider.provider_bank_workflow_type.3",
  },
];

export const providerTypeOptions = [
  {
    id: 1,
    translation_key: "provider.type.1",
  },
  {
    id: 2,
    translation_key: "provider.type.2",
  },
  {
    id: 3,
    translation_key: "provider.type.3",
  },
  {
    id: 4,
    translation_key: "provider.type.4",
  },
  {
    id: 5,
    translation_key: "provider.type.5",
  },
  {
    id: 6,
    translation_key: "provider.type.6",
  },
];

export const houseUsageTypes = [
  {
    id: 1,
    translation_key: "house.type.house",
  },
  {
    id: 2,
    translation_key: "house.type.workplace",
  },
  {
    id: 3,
    translation_key: "house.type.member",
  },
  {
    id: 4,
    translation_key: "house.type.management_office",
  },
];

export const oldRenterTypeList = [
  {
    id: 1,
    translation_key: "house.old_renter_type.left_cluster",
  },
  // {
  //   id: 2,
  //   translation_key: "house.old_renter_type.moved_to_another_house",
  // },
  {
    id: 2,
    translation_key: "house.old_renter_type.lives_in_cluster",
  },
];

export const newRenterTypeList = [
  {
    id: 1,
    translation_key: "house.new_renter_type.new_user",
  },
  {
    id: 2,
    translation_key: "house.new_renter_type.from_cluster",
  },
  {
    id: 3,
    translation_key: "house.new_renter_type.empty_house",
  },
];
export const newOwnerTypeList = [
  {
    id: 1,
    translation_key: "house.new_renter_type.new_user",
  },
  {
    id: 2,
    translation_key: "house.new_renter_type.from_cluster",
  },
];

export const residentBankIntegrationTypeList = [
  {
    id: 0,
    name: "Varsayılan",
  },
  {
    id: 1,
    name: "Onay Bekle",
  },
  {
    id: 2,
    name: "Son Ödeme Tarihine Göre Oto. Kapat",
  },
];

export const residentRequiredFieldsOptions = [
  {
    id: "identity",
    name: "Kimlik No",
  },
  {
    id: "citizenship_id",
    name: "Vatandaşlık",
  },
  {
    id: "email",
    name: "E-posta",
  },
  {
    id: "phone",
    name: "Telefon",
  },
];

export const notificationTemplateVariables = [
  {
    label: "Sakin Adı Soyadı",
    value: "{SakinAdıSoyadı}",
    example: "Ege Sertçetin",
    description: "Sakinin adı ve soyadı",
    alwaysAvailable: true,
  },
  // {
  //   label: "Aidat Borç Tutarı",
  //   value: "{AidatBorçTutarı}",
  //   example: "550,23TL",
  // },
  // {
  //   label: "Banka Hesap Adları",
  //   value: "{BankaHesapAdları}",
  //   example: "Garanti Bankası, İş Bankası",
  // },
  {
    label: "Banka Hesap IBAN Kodları",
    value: "{BankaHesapIBANKodları}",
    example: "İş Bankası TR36 0011 1000 0000 0111 6176 63",
    description: "Ödeme yapılabilecek tüm bankaların adı ve IBAN kodu",
    alwaysAvailable: true,
  },
  {
    label: "Borç Açıklama",
    value: "{BorçAçıklama}",
    example: "2023 Ocak Isınma",
    description: "İlgili borcun açıklaması",
    alwaysAvailable: false,
    availableIn: [1],
  },
  // {
  //   label: "Borç Açıklama ve Tutar",
  //   value: "{BorçAçıklamaTutar}",
  //   example: "2023 Ocak Yakıt 550,23TL",
  // },
  // {
  //   label: "Borçlu Bağımsız Bölümler",
  //   value: "{BorçluBağımsızBölümler}",
  //   example: "A019, B036",
  // },
  // {
  //   label: "Borç Tipi ve Tutar",
  //   value: "{BorçTipiTutar}",
  //   example: "Yakıt 550,23TL",
  // },
  {
    label: "Borç Tutarı",
    value: "{BorçTutarı}",
    example: "550,23TL",
    description: "İlgili borcun tutarı",
    alwaysAvailable: false,
    availableIn: [1],
  },
  // {
  //   label: "Bağımsız Bölümler",
  //   value: "{BağımsızBölümler}",
  //   example: "A019, B036",
  // },
  {
    label: "Bağımsız Bölüm",
    value: "{BağımsızBölüm}",
    example: "A019",
    description: "Blok adı ve bağımsız bölüm kapı numarası",
    alwaysAvailable: true,
  },
  // {
  //   label: "E-posta",
  //   value: "{e-posta}",
  //   example: "ceylan@example.com",
  // },
  // {
  //   label: "Gecikme Tutarı",
  //   value: "{GecikmeTutarı}",
  //   example: "13,48TL",
  // },
  {
    label: "Sakin Güncel Bakiyesi",
    value: "{SakinGüncelBakiyesi}",
    description: "Sakinin bakiyesi ve borçlu/alacaklı durumu",
    example: "852,98TL(B)",
    alwaysAvailable: true,
  },
  {
    label: "İcra Tutarı",
    value: "{İcraTutar}",
    example: "432,21TL",
    description: "Sakin ilgili bağımsız bölümden toplam icra tutarı",
    alwaysAvailable: false,
    availableIn: [5],
  },
  {
    label: "Sakin Telefonu",
    value: "{SakinTelefonu}",
    example: "+90 599 132 02 32",
    description: "Sakinin telefon numarası",
    alwaysAvailable: true,
  },
  {
    label: "Sakin Parolası",
    value: "{Parola}",
    example: "e31a4c",
    description: "Sakinin parolası.",
    alwaysAvailable: false,
    availableIn: [3],
  },
  // {
  //   label: "Son Duyuru Açıklaması",
  //   value: "{SonDuyuruAçıklaması}",
  //   example: "Sitemizde havuz inşaatı başlamıştır.",
  // },
  // {
  //   label: "Son Duyuru Tarihi",
  //   value: "{SonDuyuruTarihi}",
  //   example: "19.05.1988",
  // },
  {
    label: "Son Ödeme Tarihi",
    value: "{BorçSonÖdemeTarihi}",
    example: "31.01.2023",
    alwaysAvailable: false,
    availableIn: [1],
  },
  {
    label: "Günün Tarihi",
    value: "{Bugün}",
    example: new Date().toLocaleDateString(),
    description: "Bugünün tarihi",
    alwaysAvailable: true,
  },
  // {
  //   label: "Tahakkuk Açıklama",
  //   value: "{TahakkukAçıklama}",
  //   example: "2023 Ocak Yakıt giderleri",
  // },
  // {
  //   label: "Tahakkuk Açıklama ve Borç Tutarı",
  //   value: "{TahakkukAçıklamaBorçTutarı}",
  //   example: "2023 Ocak Yakıt giderleri 550,23TL",
  // },
  // {
  //   label: "Tahakkuk Tarihi",
  //   value: "{TahakkukTarihi}",
  //   example: "15.01.2023",
  // },
  // {
  //   label: "Tahakkuk Tipi ve Tutarı",
  //   value: "{TahakkukTipiTutarı}",
  //   example: "Yakıt 550,23TL",
  // },
  {
    label: "TYA",
    value: "{TYA}",
    example: "Yazılımcılar Sitesi",
    description: "Sakinin ilişkili olduğu toplu yaşam alanı",
    alwaysAvailable: true,
  },
  {
    label: "Aidat Son Ödeme Günü",
    value: "{AidatSonÖdemeGünü}",
    example: "23",
    description:
      "Sakinin ilişkili olduğu toplu yaşam alanının aidat son ödeme tarihi",
    alwaysAvailable: true,
  },
  {
    label: "Robosay Adresi",
    value: "{RobosayURL}",
    example: "https://web.robosay.com.tr",
    description: "Sakinin Robosay'a erişebileceği URL bilgisi",
    alwaysAvailable: true,
  },
  {
    label: "Gelir Türü Bazlı Borçlar",
    value: "{TümBorçlar}",
    example: "Isınma 512,52TL, Aidat 400,00TL",
    description: "Gelir türüne göre borç toplamları",
    alwaysAvailable: false,
    availableIn: [7],
  },
];

export const paymentWarningLevels = [
  {
    id: 0,
    name: "Yok",
  },
  {
    id: 1,
    name: "Birinci Uyarı",
  },
  {
    id: 2,
    name: "İkinci Uyarı",
  },
  {
    id: 3,
    name: "Kapatıldı",
  },
];
